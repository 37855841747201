import React from 'react';
import Value from './Value';
import log from '../utils/log';
import randKey from '../utils/randKey';
import CheckboxUtil from '../utils/CheckboxUtil';

class Row extends React.Component {
    unsubscribe;
    roles = ['cat1', 'cat2', 'cat3', 'product', 'subProduct', 'property'];

    constructor(props) {
        super(props)
        this.state = {
            values: [], 
            role: props.role, 
            cats: null, 
            errorRole: false,
        };
        this.name = React.createRef();
        this.role = React.createRef();
    }

    componentDidMount() {
        this.unsubscribe = this.props.store.subscribe(() => {
            this.updateData();
        });
        this.updateData();

        if (this.props.restoreData) {
            this.restoreValues();
        }
    }

    updateData = () => {
        this.createCatThreeHtml();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    restoreValues = () => {
        const values = this.props.restoreData.values;
        Object.keys(values).forEach((id) => {
            const value = values[id];
            const {name, sku, parentsIds, belongsIds} = value;
            const restoreData = {
                name,
                sku,
            }
            this.addValue(id, name, sku, parentsIds, belongsIds, restoreData);
        });
    }

    getRole = () => {
        return this.role.current.value;
    }

    isCat = () => {
        return ['cat1', 'cat2', 'cat3'].indexOf(this.getRole()) !== -1;
    }

    getNextNum = () => {
        let num;
        if (this.props.store.getState().row.values) {
            num = Object.keys(this.props.store.getState().row.values).length + 1;
        } else {
            num = 1;
        }
        return num;
    }

    addValueHandle = (e) => {
        let key = 'v-' + randKey(this.getNextNum());
        this.addValue(key, '', '');
    }

    addValue = (key, name, sku, parentsIds = null, belongsIds = null, restoreData = null) => {
        this.props.addValue({
            id: key, 
            value: {
                name, 
                sku, 
                paramId: this.props.id,
                parentsIds,
                belongsIds,
            }
        });

        let value = <Value 
            key={key} 
            id={key} 
            paramId={this.props.id}
            store={this.props.store} 
            getRole={this.getRole} 
            getStore={this.getStore} 
            clearFilters={this.clearFilters} 
            clearValuesCats={this.clearValuesCats} 
            clearBelongs={this.clearBelongs} 
            removeParamFilter={this.props.removeParamFilter} 
            changeValue={this.props.changeValue} 
            removeValue={this.removeValueHandle} 
            changeValueParents={this.props.changeValueParents} 
            addValueParent={this.props.addValueParent} 
            removeValueParent={this.props.removeValueParent} 
            changeValueBelongs={this.props.changeValueBelongs} 
            removeValueBelongs={this.props.removeValueBelongs} 
            clearParents={this.props.clearParents} 
            restoreData={restoreData}
        />;

        this.setState((state) => {
            let values = state.values.slice();
            values.push(value);
            return {values};
        });
    }

    removeValueHandle = (id) => {
        // Remove from store
        this.props.removeValue(id);
        
        // Remove from elems
        let oldValues = this.state.values.slice();
        let values = oldValues.filter(val => {
            return val.props.id !== id;
        });
        this.setState({values});
    }

    changeHandle = (e) => {
        let role = this.role.current.value;

        if(this.existsCat(role) || !this.validateRole(role)) {
            this.setState({errorRole: true});
            return;
        } else {
            this.setState({errorRole: false});
        }

        let data = {
            id: this.props.id,
            param: {
                role,
                name: this.name.current.value,
            }
        }
        this.props.changeParam(data);

        if (role !== this.state.role) {
            this.clearAfterChange();
        }
        this.setState({role});
    }

    clearAfterChange() {
        let values = this.getStore().values,
            paramId= this.props.id;

        if (values) {
            Object.keys(values).forEach((key) => {
                if (values[key].paramId === paramId) {
                    this.clearBelongs(key);
                }
            });
        }
    }

    removeParamHandle = () => {
        let paramId = this.props.id,
            values = this.getStore().values;

        if (values) {
            Object.keys(values).forEach((key) => {
                if (values[key].paramId === paramId) {
                    let onlyOwn = this.isCat() ? false : true;
                    this.clearFilters(key, onlyOwn);
                    this.clearValuesCats(key, onlyOwn);
                    this.clearBelongs(key);
                    this.props.removeValue(key);
                }
            });
        }
            
        this.props.removeParamHandle(paramId);
    }

    getStore() {
        return this.props.store.getState().row;
    }

    validateRole(role) {
        if(this.roles.indexOf(role) !== -1) {
            return true;
        }
        return false;
    }

    existsCat(role) {
        let params = this.props.store.getState().row.params;
        let find = Object.keys(params).filter((key) => {
            let onlyCat = ['cat1', 'cat2', 'cat3'].indexOf(params[key].role) !== -1,
                notSelf = key !== this.props.id,
                existsCat = params[key].role === role;

            if (notSelf && onlyCat && existsCat) {
                return true;
            }
            return false;
        });
        
        return find.length;
    }

    filterCats = (e) => {
        let paramId = this.props.id,
            { value, checked } = e.target;

        let parentTree = JSON.parse(e.target.getAttribute('data-parents'))

        if (checked) {
            this.props.addParamFilter({paramId, item: { valueId: value, parentTree } });
        } else {
            this.clearFilters(value, true, parentTree);
            this.clearValuesCats(value, true, parentTree);
        }
    }

    clearFilters = (valueId, onlyOwn = true, parentTree = null) => {
        let params = this.props.store.getState().row.params;
        Object.keys(params).forEach((key) => {
            if (onlyOwn && key !== this.props.id) {
                return;
            }

            //if (params[key].valuesIds && params[key].valuesIds.indexOf(valueId) !== -1) {
                this.props.removeParamFilter({paramId: key, valueId: valueId, parentTree});
            //}
        });
    }

    clearValuesCats = (parentId, onlyOwn = true, parentTree = null) => {
        let values = this.props.store.getState().row.values;

        Object.keys(values).forEach((key) => {
            if (onlyOwn && values[key].paramId !== this.props.id) {
                return;
            }
            
            let item = { valueId: parentId, parentTree };
            
            if (CheckboxUtil.existsItem(values[key].parentsIds, item)) {
                this.props.removeValueParent({valueId: key, item });

                if (this.isCat()) {
                    // Очистка кат без родителя
                    let parentTree2 = parentTree.slice();
                    parentTree2.unshift(parentId);

                    this.clearValuesCats(key, false, parentTree2);
                    this.clearFilters(key, false, parentTree2);
                }
            }
        });
    }

    clearBelongs = (valueId) => {
        if (this.isCat()) {
            return;
        }

        let values = this.getStore().values;

        Object.keys(values).forEach((key) => {
            if (values[key].belongsIds && values[key].belongsIds.indexOf(valueId) !== -1) {
                this.props.removeValueBelongs({valueId: key, belongId: valueId});
            }
        });
    }

    catThreeData = (store, level = 1, parentId = null, parentsIds = null) => {
        let stop = this.getRole() === ('cat' + level) || this.getRole() === 'property';
        if (stop || level > 3) {   
            // log('STOP ' + this.getRole());
            return;
        }

        // Cat id
        let catId;
        Object.keys(store.row.params).forEach((id) => {
            if (store.row.params[id].role === 'cat' + level) {
                catId = id;
            }
        });
        
        if (!catId) {
            return;
        }
        
        // Cats
        let cats = [];
        Object.keys(store.row.values).forEach((id) => {
            let value = store.row.values[id];

            if (value.paramId === catId && value.name) {
                if ( parentId && !CheckboxUtil.isCorrectChild( value, parentId, parentsIds ) ) {
                    return;
                }

                let filteredParentsIds;
                if (parentId) {
                    // Только те родители по которым идет цикл
                    filteredParentsIds = value.parentsIds.filter((value) => {
                        if (value.valueId === parentId) {
                            return true;
                        }
                    });
                } else {
                    filteredParentsIds = value.parentsIds;
                }

                let data = {
                        id, 
                        name: value.name,
                        //parentsIds: value.parentsIds,
                    },
                    children = this.catThreeData(store, (level + 1), id, filteredParentsIds);
                    
                if (children) {
                    data.children = children;
                }

                /*let param = store.row.params[this.props.id];
                if (param && param.valuesIds && param.valuesIds.indexOf(id) !== -1) {
                    data.checked = true;
                } */

                cats.push(data);
            }
        });

        /* 
        FIXME: УТОЧНИТЬ может 2 кат может быть только в 1, а 3 во 2?
        if (!cats && level = 1) {
            this.catThreeData(store, level + 2)
        }
        */
        return cats;
    }

    createCatThreeHtml = () => {
        let store = this.props.store.getState();
        if (! (store.row && store.row.values) ) {
            return;
        }

        if (this.state.role === 'cat1') {
            return;
        }
        
        let cats = this.catThreeData(store);
        if (! (cats && cats.length)) {
            this.setState({cats: []});
            return;
        }
        CheckboxUtil.fillParentTree(cats);
        
        if (store.row.params[this.props.id]) {
            let valuesIds = store.row.params[this.props.id].valuesIds;
            CheckboxUtil.checkedFilter(valuesIds, cats);
        }

        let html = [];
        let idx = 1;

        let htmlThree = (items, level = 1) => {
            Object.keys(items).map((key, i) => {     
                let { id, name, checked, parentTree } = items[key];
                let htmlId = 'row-' + this.props.id + '-cat-' + id + '-idx-' + idx;
                let className = 'form-check ml-' + ((level - 1) * 2);
                let disabled = this.getRole() === 'cat3' && level === 1 ? true : false;

                html.push(<div className={className} key={htmlId}>
                    <input className="form-check-input" type="checkbox" value={id} id={htmlId} data-parents={JSON.stringify(parentTree)} onChange={this.filterCats} disabled={disabled} checked={checked ? true : false} />
                    <label className="form-check-label" htmlFor={htmlId}>
                        {name}
                    </label>
                </div>);

                idx++;
                if (items[key].children) {
                    htmlThree(items[key].children, level + 1);
                }
            }); 
        }

        if (cats) {
            htmlThree(cats);
        }

        this.setState({cats: html});
    }

    render() {
        let cats = this.state.cats;
        let id = this.props.id;
        let name = this.getStore().params[this.props.id].name;
        return (
            <>
                <div className="form-row">
                    <div className="form-group col-md-2">
                        <label htmlFor={'name-' + id} className="paramName">Название параметра</label>
                        <input className="form-control" id={'name-' + id} ref={this.name} defaultValue={name} onChange={this.changeHandle} />
                    </div>
                    {cats && cats.length ? (<div className="form-group col-md-2">
                                <label htmlFor={'cats-' + id}>Категория</label>
                                {cats}
                            </div>) : null}
                    <div className="form-group col-md-2">
                        <label htmlFor={'role-' + id}>Роль параметра</label>
                        <select className={(this.state.errorRole ? "is-invalid" : '') + " form-control"} id={'role-' + id} ref={this.role} onChange={this.changeHandle} defaultValue={this.props.role}>
                            <option>Выберите роль</option>
                            <option value="cat1">Категория товара</option>
                            <option value="cat2">Подкатегория товара</option>
                            <option value="cat3">Подподкатегория товара</option>
                            <option value="product">Параметры, определяющие товар</option>
                            <option value="subProduct">Параметры, определяющие подвид товара</option>
                            <option value="property">Параметры, одинаково применяющиеся ко всем товарам</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6">
                        {this.state.values}
                        <button className="btn btn-primary mt-3 mb-3 addValue" onClick={(e) => this.addValueHandle(e)}>Добавить значение параметра</button> 
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-2">
                        <button className="btn btn-danger" onClick={(e) => this.removeParamHandle(e)}>Удалить параметр</button> 
                    </div>
                </div>
                <hr/>
            </>
        );
    }
}

export default Row;