import { 
    LOAD,
    ADD_PARAM,
    CHANGE_PARAM,
    REMOVE_PARAM,
    ADD_PARAM_FILTER,
    REMOVE_PARAM_FILTER,
    ADD_VALUE, 
    CHANGE_VALUE, 
    REMOVE_VALUE,
    ADD_VALUE_PARENT,
    REMOVE_VALUE_PARENT,
    CHANGE_VALUE_BELONGS,
    REMOVE_VALUE_BELONGS,
    CLEAR_PARENTS,

} from '../actions/RowActions';
import log from '../utils/log';
import CheckboxUtil from '../utils/CheckboxUtil';

const initialState = {}

export function rowReducer(state = initialState, action) {
    // log(action.type)
    // console.log(action.payload)
    
    switch (action.type) {
        case LOAD:
            return action.payload

        case ADD_PARAM:
            return {
                ...state, params: {
                    ...state.params, [action.payload.id]: action.payload.param
                }
            }

        case CHANGE_PARAM:
            return {
                ...state, params: {
                    ...state.params, [action.payload.id]: {
                        ...state.params[action.payload.id], ...action.payload.param
                    }
                }
            }

        case REMOVE_PARAM:
            let { [action.payload.paramId]: removeParam, ...params } = state.params;
            return {
                ...state, params: {
                    ...params
                }
            }

        case ADD_PARAM_FILTER:
            let filter = state.params[action.payload.paramId].valuesIds;
            if (!filter) {
                filter = [];
            }

            /* log('ADD_PARAM_FILTER', 'red')
            console.log(action.payload) */

            if (!existsItem(filter, action.payload.item)) {
                filter.push(action.payload.item);
            }

            return {
                ...state, params: {
                    ...state.params, [action.payload.paramId]: {
                        ...state.params[action.payload.paramId], valuesIds: filter
                    }
                }
            }

        case REMOVE_PARAM_FILTER:
            let filterForRemove = state.params[action.payload.paramId].valuesIds;
            if (!filterForRemove) {
                return {...state};
            }

            /* log('REMOVE_PARAM_FILTER', 'red')
            console.log(action.payload)
            console.log(filterForRemove) */

            if (!action.payload.parentTree) {
                action.payload.parentTree = [];
            }

            let newFilter = removeItem(filterForRemove, action.payload);

            return {
                ...state, params: {
                    ...state.params, [action.payload.paramId]: {
                        ...state.params[action.payload.paramId], valuesIds: newFilter
                    }
                }
            }

        case ADD_VALUE:
            return {
                ...state, values: {
                    ...state.values, [action.payload.id]: action.payload.value
                }
            }

        case CHANGE_VALUE:
            return {
                ...state, values: {
                    ...state.values, [action.payload.id]: {
                        ...state.values[action.payload.id], ...action.payload.value
                    }
                } 
            }

        case REMOVE_VALUE:
            let { [action.payload]: removeValue, ...values } = state.values;
            return {
                ...state, values: {
                    ...values
                } 
            }

        case ADD_VALUE_PARENT:
            let parentsIds2 = state.values[action.payload.valueId].parentsIds;
            if (!parentsIds2) {
                parentsIds2 = [];
            }

            /* log(ADD_VALUE_PARENT, 'blue');
            console.log(action.payload) */
            
            // if (parentsIds2.indexOf(action.payload.parentId) === -1) {
            if (!existsItem(parentsIds2, action.payload)) {
                parentsIds2.push(action.payload.item);
            }

            return {
                ...state, values: {
                    ...state.values, [action.payload.valueId]: {
                        ...state.values[action.payload.valueId], parentsIds: parentsIds2
                    }
                }
            }

        case REMOVE_VALUE_PARENT:
            let valueParentsForRemove = state.values[action.payload.valueId].parentsIds;
            if (!valueParentsForRemove) {
                return {...state};
            }
            
            /* log(REMOVE_VALUE_PARENT, 'blue');
            console.log(action.payload) */

            if (!action.payload.item.parentTree) {
                action.payload.item.parentTree = [];
            }

            let newValueParents = removeItem(valueParentsForRemove, action.payload.item);

            return {
                ...state, values: {
                    ...state.values, [action.payload.valueId]: {
                        ...state.values[action.payload.valueId], parentsIds: newValueParents
                    }
                }
            }

        case CHANGE_VALUE_BELONGS:
            if (! (action.payload.valueId && action.payload.belongId) ) {
                return state;
            }

            let belongsIds;
            if (state.values[action.payload.valueId].belongsIds) {
                belongsIds = state.values[action.payload.valueId].belongsIds;
            } else {
                belongsIds = [];
            }
            
            if (belongsIds.indexOf(action.payload.belongId) === -1) {
                belongsIds.push(action.payload.belongId);
            }

            return {
                ...state, values: {
                    ...state.values, [action.payload.valueId]: {
                        ...state.values[action.payload.valueId], belongsIds: belongsIds
                    }
                }
            }

        case REMOVE_VALUE_BELONGS:
            if (!state.values[action.payload.valueId].belongsIds) {
                return state;
            }

            let belongsIds2 = state.values[action.payload.valueId].belongsIds.filter((value) => {
                if (value !== action.payload.belongId) {
                    return true;
                }
                return false;
            });

            return {
                ...state, values: {
                    ...state.values, [action.payload.valueId]: {
                        ...state.values[action.payload.valueId], belongsIds: belongsIds2
                    }
                }
            }

        case CLEAR_PARENTS:
            // log('CLEAR_PARENTS');
            let clearValues = {...state.values};
            Object.keys(clearValues).forEach((key) => {
                let item = clearValues[key];
                if (!item.parentsIds) {
                    return;
                }

                // Not exists or not correct parentTree 
                let parentsIds = item.parentsIds.filter((value) => {
                    if (clearValues[value['valueId']]) {
                        let findValue = clearValues[value['valueId']];

                        if (findValue && findValue.parentsIds && value.parentTree) {

                            let exists = false;
                            let [valueId, ...valueTree] = value.parentTree;

                            findValue.parentsIds.forEach(findValueParent => {
                                // log(`${findValueParent.valueId} === ${valueId}`, 'orange');
                                if (findValueParent.valueId === valueId && findValueParent.parentTree) {

                                    let equals = true;
                                    findValueParent.parentTree.forEach(value1 => {
                                        valueTree.forEach(value2 => {
                                            // log(`${value1} === ${value2}`, 'red');
                                            if (value1 !== value2) {
                                                equals = false;
                                            }
                                        });
                                    });

                                    if (equals) {
                                        exists = true;
                                        return;
                                    }
                                } else if (findValueParent.valueId === valueId) {
                                    exists = true;
                                    return;
                                }
                            });

                            return exists;
                        } else if (findValue) {
                            return true;
                        }
                    }
                });

                item['parentsIds'] = parentsIds;
            });

            return {
                ...state, values: {
                    ...clearValues
                }
            }

        default:
            return state
    }
}


function existsItem(ids, findItem) {
    return CheckboxUtil.existsItem(ids, findItem);
}

function removeItem(ids, removeItem) {
    let newIds = ids.filter((value) => {
        let parentsTreeEqual = JSON.stringify(value.parentTree) === JSON.stringify(removeItem.parentTree),
            valuesEqual = value.valueId === removeItem.valueId;

        if (valuesEqual && parentsTreeEqual) {
            return false;
        }
        return true;
    });

    return newIds;
}


/**
 * Selectors
 * 
 * Get products for subProducts
 */
export function getBelongsSelector(state, props) {
    if (! (state.row && state.row.values) ) {
        return;
    }
    state = {...state.row};

    if ( !(props.getRole() === 'product' || props.getRole() === 'subProduct') ) {
        return;
    }

    let params = {};
    Object.keys(state.params).forEach((paramId) => {
        let isSelfParam = paramId === props.paramId;
        if (state.params[paramId].role !== 'product' || isSelfParam) {
            return;
        }

        // FIXME: add 5 in 4, but it has double in export
        /* if (props.getRole() === 'product') {
            if (state.params[paramId].role !== 'subProduct' || isSelfParam) {
                return;
            }
        } else if (props.getRole() === 'subProduct') {
            if (state.params[paramId].role !== 'product' || isSelfParam) {
                return;
            }
        } */
        

        let values = {};
        Object.keys(state.values).forEach((valueId) => {
            let value = state.values[valueId];
            if (value.paramId !== paramId) {
                return;
            }

            let {name} = value;
            if (!name) {
                return;
            }

            // Checkk relation parentsIds
            let isCorrectRelation = false;
            let selfValue = state.values[props.id];

            /* console.table(selfValue['parentsIds']);
            console.info('>>>');
            console.table(value['parentsIds']); */

            if ( !(selfValue && value && selfValue['parentsIds'] && value['parentsIds']) ) {
                return;
            }
            
            selfValue['parentsIds'].forEach(selfItem => {
                if (isCorrectRelation) {
                    return;
                }
                value['parentsIds'].forEach(otherItem => {
                    // console.log(`(${value['name']}) ${otherItem['valueId']} === ${selfItem['valueId']} (${selfValue['name']})`)
                    if (otherItem['valueId'] === selfItem['valueId']) {
                        isCorrectRelation = true;
                        return;
                    }
                });
            });

            if (!isCorrectRelation) {
                return;
            }

            values[valueId] = value;
        });

        if (Object.keys(values).length) {
            params[paramId] = {
                'name': state.params[paramId].name,
                'values': values,
            };
        }
    });
    
    return params;
}