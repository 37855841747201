import React from 'react'
import log from '../utils/log';

export default class CheckboxUtil {
    static fillParentTree = (items/* , level = 0, parentTree = [] */) => {  
        items.forEach((value) => {
            value.parentTree = [];

            if (value.children && value.children.length) {
                let parentTree = value.parentTree.slice();
                parentTree.unshift(value.id);
                
                value.children.forEach((value2) => {
                    let parentTree2 = parentTree.slice();
                    value2.parentTree = parentTree.slice();

                    if (value2.children && value2.children.length) {
                        parentTree2.unshift(value2.id);

                        value2.children.forEach((value3) => {
                            value3.parentTree = parentTree2.slice();
                        });
                    }
                });
            }
        });
        
        /* log('fillParentTree - ' + level);
        if (level === 0) {
            parentTree = [];
        }
        items.forEach((value) => {
            log(value.id);

            value.parentTree = parentTree.slice();

            if (value.children && value.children.length) {
                parentTree.unshift(value.id);
                this.fillParentTree(value.children, (level + 1), parentTree);
            }
        });

        parentTree = []; */
    }

    static checkedFilter = (ids, items, field = 'checked') => {
        items.forEach((value) => {
            value[field] = CheckboxUtil.isCheckedFilter(ids, value.id, value.parentTree);
            if (value.children) {
                this.checkedFilter(ids, value.children, field);
            }
        });
    }

    static isCheckedFilter = (valuesIds, valueId, parentTree) => {
        /* console.log(param);
        console.log(valueId);
        console.log(parentTree); */

        let checked = false;
        if (valuesIds) {
            valuesIds.forEach((paramValue) => {
                // console.log(paramValue);
                if (paramValue.valueId === valueId) {
                    if (JSON.stringify(paramValue.parentTree) === JSON.stringify(parentTree)) {
                        checked = true;
                        return;
                    }
                }
            });
        }
        return checked;
    }

    /* static clearParents = (items, parentItem) => {
        items.forEach((item) => {
            log('clearParents', 'green');
            let wrongChilren = false;

            if (parentItem) { 
                if (!item.parentsIds) {
                    wrongChilren = true;
                    return;
                }

                item.parentsIds.forEach(value => {
                    if (value.valueId === parentItem.id) {

                        if (!parentItem.parentsIds) {
                            wrongChilren = true;
                            return;
                        }

                        parentItem.parentsIds.forEach(parentValue => {
                            let parentTree = parentValue.slice();
                            parentTree.unshift(parentValue.valueId);
                            
                            let find = JSON.stringify(value.parentTree) === JSON.stringify(parentValue.parentTree);
                            if (!find) {
                                wrongChilren = true;
                                log('FIND', 'black');
                                return;
                            }
                        });
                    }
                });

                if (wrongChilren) {
                    log('wrongChilren', 'black');
                    console.log(item)
                }
            }

            if (item.children) {
                parentItem = {id: item.id, parentsIds: item.parentsIds};
                this.clearParents(item.children, parentItem);
            }
        });
    } */

    static existsItem = (ids, findItem) => {
        let exists = false;

        if (ids) {
            ids.forEach((item) => {
                let equalParents = JSON.stringify(item.parentTree) === JSON.stringify(findItem.parentTree),
                    equalValues = item.valueId === findItem.valueId;

                if (equalValues && equalParents) {
                    exists = true;
                    return;
                }
            });
        }

        return exists;
    }

    /* static existsItemId = (ids, findId) => {
        let exists = false;

        if (ids) {
            ids.forEach((item) => {
                if (item.valueId === findId) {
                    exists = true;
                    return;
                }
            });
        }

        return exists;
    } */

    static isCorrectChild = (value, parentId, parentsIds) => {
        let correct = false;
                    
        if (!parentsIds) {
            parentsIds = [];
        }

        if (value.parentsIds) {
            // Cycle parents
            correct = value.parentsIds.some(childValue => {
                if (childValue.valueId === parentId) {
                    if (!parentsIds.length) {
                        //correct = true;
                        return true;
                    }
                    
                    // Cycle parent's parents
                    correct = parentsIds.some(parentValue => {

                        if (childValue.valueId !== parentId) {
                            return true;
                        }

                        let tree = parentValue.parentTree.slice();
                        tree.unshift(parentValue.valueId);

                        /* log('- tree 1 -', 'blue');
                        console.log(tree)
                        log('- tree 2 -', 'blue');
                        console.log(childValue.parentTree) */
                        
                        let find = JSON.stringify(childValue.parentTree) === JSON.stringify(tree);
                        
                        /* log('- result tree -', 'blue');
                        console.log(find) */

                        if (find) {
                            //correct = true;
                            return true;
                        }
                    });

                    return correct;
                }
            });
        } 

        /* if (!correct) {
            log('Wrong child', 'indianred');
            console.log(value);
        }
        log('End isCorrectChild') */

        return correct;
    }
}