export const LOAD = 'LOAD'
export const ADD_PARAM = 'ADD_PARAM'
export const CHANGE_PARAM = 'CHANGE_PARAM'
export const REMOVE_PARAM = 'REMOVE_PARAM'
//export const CHANGE_PARAM_FILTER = 'CHANGE_PARAM_FILTER'
export const ADD_PARAM_FILTER = 'ADD_PARAM_FILTER'
export const REMOVE_PARAM_FILTER = 'REMOVE_PARAM_FILTER'
export const ADD_VALUE = 'ADD_VALUE'
export const CHANGE_VALUE = 'CHANGE_VALUE'
export const REMOVE_VALUE = 'REMOVE_VALUE'
export const CHANGE_VALUE_PARENTS = 'CHANGE_VALUE_PARENTS'
export const ADD_VALUE_PARENT = 'ADD_VALUE_PARENT'
export const REMOVE_VALUE_PARENT = 'REMOVE_VALUE_PARENT'
export const CHANGE_VALUE_BELONGS = 'CHANGE_VALUE_BELONGS'
export const REMOVE_VALUE_BELONGS = 'REMOVE_VALUE_BELONGS'
export const CLEAR_PARENTS = 'CLEAR_PARENTS'

export function load(param) {
    return {
        type: LOAD,
        payload: param,
    }
}

export function addParam(param) {
    return {
        type: ADD_PARAM,
        payload: param,
    }
}

export function changeParam(param) {
    return {
        type: CHANGE_PARAM,
        payload: param,
    }
}

export function removeParam(param) {
    return {
        type: REMOVE_PARAM,
        payload: param,
    }
}

export function addParamFilter(param) {
    return {
        type: ADD_PARAM_FILTER,
        payload: param,
    }
}

export function removeParamFilter(param) {
    return {
        type: REMOVE_PARAM_FILTER,
        payload: param,
    }
}

export function addValue(param) {
    return {
        type: ADD_VALUE,
        payload: param,
    }
}

export function changeValue(param) {
    return {
        type: CHANGE_VALUE,
        payload: param,
    }
}

export function removeValue(param) {
    return {
        type: REMOVE_VALUE,
        payload: param,
    }
}

export function addValueParent(param) {
    return {
        type: ADD_VALUE_PARENT,
        payload: param,
    }
}

export function removeValueParent(param) {
    return {
        type: REMOVE_VALUE_PARENT,
        payload: param,
    }
}

export function changeValueBelongs(param) {
    return {
        type: CHANGE_VALUE_BELONGS,
        payload: param,
    }
}

export function removeValueBelongs(param) {
    return {
        type: REMOVE_VALUE_BELONGS,
        payload: param,
    }
}

export function clearParents(param) {
    return {
        type: CLEAR_PARENTS,
        payload: param,
    }
}