import React from 'react';
import Row from '../components/Row';
import 'bootstrap/dist/css/bootstrap.css';
import ReactJson from 'react-json-view';

import { connect } from 'react-redux'
import { 
    load, 
    addParam, 
    changeParam, 
    removeParam, 
    addParamFilter, 
    removeParamFilter, 
    addValue, 
    changeValue, 
    removeValue, 
    addValueParent, 
    removeValueParent, 
    changeValueBelongs,
    removeValueBelongs,
    clearParents,
} from '../actions/RowActions'
import log from '../utils/log';
import randKey from '../utils/randKey';

class App extends React.Component {
    debug = false;
    // url = 'http://a-vinduet-export.loc'
    url = 'server/index.php'

    constructor(props) {
        super(props);
        this.state = {rows: [], draw: null, enableLoad: true,};
    }

    componentDidMount() {
        this.unsubscribe = this.props.store.subscribe(() => {
            // this.setState({draw: this.props.store.getState().row});
        });
    }

    getNextNum = () => {
        let num;
        if (this.props.store.getState().row.params) {
            num = Object.keys(this.props.store.getState().row.params).length + 1;
        } else {
            num = 1;
        }
        return num;
    }
    
    getNextRole = () => {
        let role = '',
            store = this.props.store.getState().row;

        if (store.params) {
            let params = store.params;

            Object.keys(params).forEach((key) => {
                if (params[key].role === 'cat1') {
                    role = 'cat2';
                }
                if (params[key].role === 'cat2') {
                    role = 'cat3';
                }
                if (params[key].role === 'cat3') {
                    role = 'product';
                }

                if (!role) {
                    role = 'cat1';
                }
            });
        } else {
            role = 'cat1';
        }

        return role;
    }

    addParamHandle = (e) => {
        let key = 'p-' + randKey(this.getNextNum()),
            role = this.getNextRole(),
            name = '',
            valuesIds = [];

        this.addParam(key, role, name, valuesIds);
    }

    addParam(key, role, name, valuesIds, restoreData = null) {
        /* console.log(key);
        console.log(role);
        console.log(name);
        console.log(valuesIds);
        console.log(restoreData); */

        const { 
            store, 
            addParam, 
            changeParam, 
            addParamFilter,
            removeParamFilter,
            addValue, 
            changeValue, 
            removeValue, 
            addValueParent, 
            removeValueParent, 
            changeValueBelongs, 
            removeValueBelongs, 
            clearParents, 
        } = this.props;

        addParam({
            id: key, 
            param: {
                role, 
                name,
                valuesIds,
            }
        });
        
        //let rows = this.state.rows.slice();
        let row = <Row 
            key={key} 
            id={key} 
            role={role} 
            store={store} 
            changeParam={changeParam} 
            removeParamHandle={this.removeParamHandle} 
            addParamFilter={addParamFilter} 
            removeParamFilter={removeParamFilter} 
            addValue={addValue} 
            changeValue={changeValue} 
            removeValue={removeValue} 
            addValueParent={addValueParent}
            removeValueParent={removeValueParent}
            changeValueBelongs={changeValueBelongs}
            removeValueBelongs={removeValueBelongs}
            clearParents={clearParents}
            restoreData={restoreData}
        />;

        this.setState((state) => {
            let rows = state.rows.slice();
            rows.push(row);
            return {rows};
        });
        
        this.setState({enableLoad: false});
    }

    removeParamHandle = (id) => {
        this.props.removeParam({paramId: id});
        
        let oldRows = this.state.rows.slice();
        let rows = oldRows.filter(row => {
            return row.props.id !== id;
        });
        this.setState({rows});
    }

    submitHandle = () => {
        let data = this.props.store.getState().row;
        console.table(data.params);
        console.table(data.values);
        console.log(JSON.stringify(data));

        fetch(this.url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "data=" + JSON.stringify(this.props.store.getState().row),
        })
            /*.then((response) => {
                console.log(response)
                if (response) {
                    return response.json();
                }
            })
            .then((response) => {
                console.log(response);
            });*/
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "filename.xlsx";
                document.body.appendChild(a);
                a.click();    
                a.remove();      
            });
    }

    
    saveHandle = async () => {
        const body = JSON.stringify(this.props.store.getState().row);
        const url = this.url + '?save=1';

        let response = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'data=' + body,
        });
        let data = await response.json();
        // TODO: отключить кнопку
        // console.log(data);
    }
    
    loadHandle = async () => {
        const url = this.url + '?load=1';
        let response = await fetch(url, {
            method: 'get',
        });
        let data = await response.json();
        //console.log(data);

        if (!data.params) {
            return;
        }

        Object.keys(data.params).forEach((paramId) => {
            const param = data.params[paramId]
            const { role, name, valuesIds } = param
            //console.log(param)

            let values = {}
            if (data.values) {
                Object.keys(data.values).forEach((valueId) => {
                    const value = data.values[valueId]
                    if (value.paramId === paramId) {
                        values[valueId] = value
                    }
                })
            }

            const restoreData = {
                name,
                role,
                values,
            }

            this.addParam(paramId, role, name, valuesIds, restoreData)
        });
        
        this.setState({enableLoad: false});
    }

    render() {
        //log('Render App', 'red');
        return (
            <div className="container-fluid">
                {this.state.rows}
                <button className="btn btn-primary mt-3 mb-3 addParam" onClick={(e) => this.addParamHandle(e)}>Добавить параметр</button>  
                <div>
                    <button className="btn btn-success mt-3 mb-3" onClick={this.submitHandle}>Сгенерировать основную часть артикула</button> 
                </div>
                <div>
                    <button className="btn btn-success mt-3 mb-3" onClick={this.saveHandle}>Сохранить</button>  
                    { this.state.enableLoad && <button className="btn btn-info mt-3 mb-3" onClick={this.loadHandle}>Загрузить</button> }
                </div>
                {this.debug && this.state.draw && <ReactJson src={this.state.draw} collapsed={false} />}
            </div>
        );
    }
}


const mapStateToProps = store => {
    return {
        row: store.row,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        load: param => dispatch(load(param)),
        addParam: param => dispatch(addParam(param)),
        changeParam: param => dispatch(changeParam(param)),
        removeParam: param => dispatch(removeParam(param)),
        addParamFilter: param => dispatch(addParamFilter(param)),
        removeParamFilter: param => dispatch(removeParamFilter(param)),
        addValue: param => dispatch(addValue(param)),
        changeValue: param => dispatch(changeValue(param)),
        removeValue: param => dispatch(removeValue(param)),
        addValueParent: param => dispatch(addValueParent(param)),
        removeValueParent: param => dispatch(removeValueParent(param)),
        changeValueBelongs: param => dispatch(changeValueBelongs(param)),
        removeValueBelongs: param => dispatch(removeValueBelongs(param)),
        clearParents: param => dispatch(clearParents(param)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps // TODO: сократить
)(App)