import React from 'react';
import log from '../utils/log';
import CheckboxUtil from '../utils/CheckboxUtil';
import { getBelongsSelector } from '../reducers/row';

class Value extends React.Component {
    unsubscribe;

    constructor(props) {
        super(props);
        this.state = {cats: null, params: null};
        this.name = React.createRef();
        this.sku = React.createRef();
    }

    componentDidMount() {
        this.unsubscribe = this.props.store.subscribe(() => {
            this.updateData();
        });
        this.updateData();
    }

    updateData = () => {
        let store = this.props.store.getState();
        this.createCatThree(store);
        this.getBelongs(store);
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    getStore() {
        return this.props.store.getState().row;
    }

    catThreeData = (store, level = 1, parentId = null, parentsIds = null) => {
        let stop = this.props.getRole() === ('cat' + level) || this.props.getRole() === 'property';
        if (stop || level > 3) {  
            // log('STOP ' + this.props.getRole());  
            return;
        }

        // Cat id
        let catId;
        Object.keys(store.row.params).forEach((id) => {
            if (store.row.params[id].role === 'cat' + level) {
                catId = id;
            }
        });
        
        if (!catId) {
            return;
        }
        
        // Cats
        let cats = [];
        Object.keys(store.row.values).forEach((id) => {
            let value = store.row.values[id];

            if (value.paramId === catId && value.name) {

                if ( parentId && !CheckboxUtil.isCorrectChild( value, parentId, parentsIds ) ) {
                    return;
                }

                let filteredParentsIds;
                if (parentId) {
                    // Только те родители по которым идет цикл
                    filteredParentsIds = value.parentsIds.filter((value) => {
                        if (value.valueId === parentId) {
                            return true;
                        }
                    });
                } else {
                    filteredParentsIds = value.parentsIds;
                }

                let data = {
                        id, 
                        name: value.name,
                    },
                    children = this.catThreeData(store, (level + 1), id, filteredParentsIds);

                if (children) {
                    data.children = children;
                }

                /* const selfValue = store.row.values[this.props.id];
                data.checked = false;
                if (selfValue.parentsIds && selfValue.parentsIds.indexOf(id) !== -1) {
                    data.checked = true;
                } */


                // FIXME: isCheckedFilter
                /* const enabledCats = store.row.params[this.props.paramId].valuesIds;
                if (enabledCats) {
                    data.disabled = enabledCats.indexOf(id) === -1;
                } */

                cats.push(data);
            }
        });

        return cats;
    }

    createCatThree = (store) => {
        if (! (store.row && store.row.values && store.row.params) ) {
            return;
        }
        //store = store.row;

        if (this.props.getRole() === 'cat1') {
            return;
        }

        let cats = this.catThreeData(store);
        if (! (cats && cats.length)) {
            this.setState({cats: []});
            return;
        }
        CheckboxUtil.fillParentTree(cats);
        // console.log(cats);

        if (store.row.values[this.props.id]) {   
            let parentsIds = store.row.values[this.props.id].parentsIds;
            CheckboxUtil.checkedFilter(parentsIds, cats);
        }

        if (store.row.params[this.props.paramId]) {
            let valuesIds = store.row.params[this.props.paramId].valuesIds;
            CheckboxUtil.checkedFilter(valuesIds, cats, 'enabled');
            //console.log(cats);
        }

        let html = [];
        let idx = 1;

        let htmlThree = (items, level = 1) => {
            Object.keys(items).map((key, i) => {     
                let { id, name, checked, parentTree, enabled } = items[key];
                let htmlId = 'row-' + this.props.id + '-cat-' + id + '-idx-' + idx;
                let className = 'form-check ml-' + ((level - 1) * 2);
                let disabled = this.props.getRole() === 'cat3' && level === 1 ? true : false;

                if (!enabled) {
                    disabled = true;
                }

                html.push(<div className={className} key={htmlId}>
                    <input className="form-check-input" type="checkbox" value={id} id={htmlId} data-parents={JSON.stringify(parentTree)} onChange={this.changeValueParents} disabled={disabled} checked={checked} />
                    <label className="form-check-label" htmlFor={htmlId}>
                        {name}
                    </label>
                </div>);

                if (items[key].children) {
                    htmlThree(items[key].children, level + 1);
                }

                idx++;
            });
        }

        if (cats) {
            htmlThree(cats);
        }

        html.unshift(<label key="label">Категория</label>);
        this.setState({cats: html});
    }

    changeValueParents = (e) => {
        let valueId = this.props.id,
            { value, checked } = e.target;

        let parentTree = JSON.parse(e.target.getAttribute('data-parents'))

        if (checked) {
            this.props.addValueParent({valueId, item: { valueId: value, parentTree }});
        } else {
            this.props.removeValueParent({valueId, item: { valueId: value, parentTree }});

            parentTree.unshift(value);
            this.clearFilters(valueId, parentTree);
            this.props.clearParents({valueId: value});
        }
    }

    clearFilters = (valueId, parentTree) => {
        let params = this.getStore().params;
        Object.keys(params).forEach((key) => {
            if (key === this.props.paramId) {
                return;
            }
            this.props.removeParamFilter({paramId: key, valueId: valueId, parentTree});
        });
    }
    
    changeHandle = (e) => {
        this.props.changeValue({id: this.props.id, value: {
            name: this.name.current.value, 
            sku: this.sku.current.value, 
            paramId: this.props.paramId,
        }});
    }

    removeHandle = () => {
        let id = this.props.id;
        this.props.clearFilters(id, false);
        this.props.clearValuesCats(id);
        this.props.clearBelongs(id);
        this.props.removeValue(id);
    }

    changeValueBelongsHandle = (e) => {
        let valueId = this.props.id;
        if (e.target.checked) {
            this.props.changeValueBelongs({valueId, belongId: e.target.value});
        } else {
            this.props.removeValueBelongs({valueId, belongId: e.target.value});
        }
    }

    getBelongs = (store) => {
        /* 
        if (! (store.row && store.row.values) ) {
            return;
        }
        store = store.row;
        let selectsParams = [];

        if ( !(this.props.getRole() === 'product' || this.props.getRole() === 'subProduct') ) {
            return;
        }

        Object.keys(store.params).forEach((paramId) => {
            let isSelfParam = paramId === this.props.paramId;
            if (store.params[paramId].role !== 'product' || isSelfParam) {
                return;
            }

            let selectParam = [];
            
            Object.keys(store.values).forEach((valueId) => {
                if (store.values[valueId].paramId !== paramId) {
                    return;
                }

                let {name} = store.values[valueId];

                if (!name) {
                    return;
                }

                let checked = false,
                    selfValue = store.values[this.props.id];
                if (selfValue && selfValue.belongsIds && selfValue.belongsIds.indexOf(valueId) !== -1) {
                    checked = true;
                }

                let htmlId = this.props.paramId + '--' + valueId + '--' + this.props.id;
                selectParam.push(
                    <div className="form-check" key={htmlId}>
                        <input className="form-check-input" type="checkbox" value={valueId} id={htmlId} onChange={this.changeValueBelongsHandle} checked={checked ? true : false} />
                        <label className="form-check-label" htmlFor={htmlId}>
                            {name}
                        </label>
                    </div>
                );
            });

            if (selectParam.length) {
                selectParam.unshift(<label key="label">Параметр: {store.params[paramId].name}</label>);
                selectsParams.push(selectParam);
            }
        });

        this.setState({params: selectsParams});
        */

        let params = getBelongsSelector(store, this.props);
        let selectsParams = [];

        if (typeof params == 'object' && Object.keys(params).length) {
            
            Object.keys(params).forEach((paramId) => {
                let param = params[paramId];
                let selectParam = [];

                // console.table(param.values);
                Object.keys(param.values).forEach((valueId) => {
                    let {name} = param.values[valueId];
                    let checked = false,
                        selfValue = store.row.values[this.props.id];

                    if (selfValue && selfValue.belongsIds && selfValue.belongsIds.indexOf(valueId) !== -1) {
                        checked = true;
                    }

                    let htmlId = this.props.paramId + '--' + valueId + '--' + this.props.id;
                    selectParam.push(
                        <div className="form-check" key={htmlId}>
                            <input className="form-check-input" type="checkbox" value={valueId} id={htmlId} onChange={this.changeValueBelongsHandle} checked={checked ? true : false} />
                            <label className="form-check-label" htmlFor={htmlId}>
                                {name}
                            </label>
                        </div>
                    );
                });

                if (selectParam.length) {
                    selectParam.unshift(<label key="label">Параметр: {param.name}</label>);
                    selectsParams.push(selectParam);
                }
            });
        }

        this.setState({params: selectsParams});
    }

    render() {
        //log('Render Value', 'blue');
        let id = this.props.id;
        return (
            <>
                <div className="form-row value">
                    <div className="form-group col-md-6">
                        <label htmlFor={'name-' + id}>Название значения параметра</label>
                        <input className="form-control" id={'name-' + id} ref={this.name} defaultValue={this.props.restoreData ? this.props.restoreData.name : ''} onChange={this.changeHandle} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor={'sku-' + id}>Артикул</label>
                        <input className="form-control" id={'sku-' + id} ref={this.sku} defaultValue={this.props.restoreData ? this.props.restoreData.sku : ''} onChange={this.changeHandle} />
                    </div>
                    <div className="form-group col-md-2 align-self-end">
                        <button className="btn btn-danger" onClick={this.removeHandle}>Удалить</button> 
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        {this.state.cats}
                    </div>
                    <div className="form-group col-md-6">
                        {this.state.params ? this.state.params.map((param) => param) : null}
                    </div>
                </div>
                <hr/>
            </>
        );
    }
}

export default Value;